import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import teamStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/teamStyle.js";

const useStyles = makeStyles(teamStyle);

export default function SectionTeam() {
  const classes = useStyles();
  return (
    <div className={classes.team}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>
            J&T Plasticware’s story is a story of humility.
          </h2>
          <h5 className={classes.description}>
            We started small in the early 90s offering items like hangers,
            clips, baskets, trash cans, food containers, and plates. We had a
            simple mission: to provide plasticware for Filipino families and
            hope that our products make their lives convenient. Though our
            mission may appear modest, it was rooted in sincerity. Our
            earnestness combined with years of experience in the plastic
            industry transformed us as we became one of the biggest plasticware
            manufacturing companies in the Philippines and in the Asia-Pacific
            region.
          </h5>
          <h5 className={classes.description}>
            Through the years, we diversified our roster of products as we
            recognize the growing and ever-changing needs of our consumers. We
            have come up with more products that display modern and stylish
            designs, outstanding quality, and user convenience. Our wide range
            of items now includes closet, cabinet, chest drawers, kitchen
            organizers, functional boxes, monobloc chairs, tables, and many
            more.
          </h5>
          <h5 className={classes.description}>
            Our success has pushed us to never stop innovating as we
            continuously develop quality plasticware and rightfully be{" "}
            <strong>the Pambansang Plasticware.</strong>
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}
