import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";
import Load from "components/Loading/Loading";
// import { ApolloProvider } from "react-apollo";
// import ApolloClient from "apollo-boost";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

// pages for this product
import AboutUsPage from "views/AboutUsPage/AboutUsPage.js";
import ContactUsPage from "views/ContactUsPage/ContactUsPage.js";
import Loadable from "react-loadable";

const LandingPage = Loadable({
  loader: () => import("views/LandingPage/LandingPage.js"),
  loading: Load,
});

const EcommercePage = Loadable({
  loader: () => import("views/EcommercePage/EcommercePage"),
  loading: Load,
});

const Products = Loadable({
  loader: () => import("views/EcommercePage/ProductsPage.js"),
  loading: Load,
});

const Product = Loadable({
  loader: () => import("views/EcommercePage/ProductPage.js"),
  loading: Load,
});

var hist = createBrowserHistory();

// const client = new ApolloClient({
//   uri: "http://localhost:4000/graphql",
//   uri: "https://jtplasticserver.herokuapp.com/graphql",
// });

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route exact path="/gallery" component={EcommercePage} />
      <Route path="/contact" component={ContactUsPage} />}
      <Route path="/about" component={AboutUsPage} />
      <Route path="/e/:type/:id" component={Product} />
      <Route path="/e/:type" component={Products} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
