/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components used to create a google map

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";

// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Button from "components/CustomButtons/Button.js";
import Footer from "components/Footer/Footer.js";

import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";

const useStyles = makeStyles(contactUsStyle);

export default function ContactUsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();

  return (
    <div>
      <Header
        brand="J&T Plasticware"
        links={<HeaderLinks dropdownHoverColor="danger" page="contact" />}
        fixed
        color="danger"
      />
      <div className={classes.bigMap}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3858.7686334946998!2d120.99373791456489!3d14.725668689722882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397b17bca700769%3A0x1bc27bd7a092c7e2!2sMalinis%20Road%2C%20Valenzuela%2C%20Metro%20Manila!5e0!3m2!1sen!2sph!4v1596299849172!5m2!1sen!2sph"
          width="100%"
          height="500px"
          frameborder="0"
          style={{ border: 0 }}
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <h2 className={classes.title}>Send us a message</h2>
            <GridContainer>
              <GridItem md={2}></GridItem>
              <GridItem md={8} sm={12}>
                <p>
                  You can contact us with anything related to our Products. We
                  {"'"}ll get in touch with you as soon as possible.
                  <br />
                  <br />
                </p>
                <Button
                  color="facebook"
                  block
                  href="https://www.m.me/Jtplasticware"
                  target="_blank"
                >
                  <i className="fab fa-facebook-square" /> Contact Us via
                  Messenger
                </Button>
                <Button
                  color="google"
                  block
                  href="mailto:info@jtplasticware.com"
                >
                  <i className="fab fa-google" /> Send us an email via Gmail
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>

      <Footer
        content={
          <div className={classes.footRight}>
            &copy; {1900 + new Date().getYear()}, Let{"'"}s make websites great
            again.{" "}
            <span className="cqBrand">
              <a
                href="https://www.linkedin.com/in/charlesquimpo"
                target="_blank"
                rel="noreferrer"
              >
                CQ Studios
              </a>
            </span>
          </div>
        }
      />
    </div>
  );
}
