import React from "react";
// nodejs library that concatenates classes
// import classNames from "classnames";
import Button from "components/CustomButtons/Button.js";
import { Link } from "react-router-dom";

export default function CatNav(props) {
    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
            }}
        >
            <Link to="/e/cabinets">
                <Button
                    aria-label="button"
                    color="white"
                    style={{ color: "#a60101", fontWeight: 500 }}
                    onClick={props.tog}
                >
                    Cabinets
                </Button>
            </Link>
            <Link to="/e/drawers">
                <Button
                    aria-label="button"
                    color="white"
                    style={{ color: "#a60101", fontWeight: 500 }}
                    onClick={props.tog}
                >
                    Drawers
                </Button>
            </Link>
            <Link to="/e/organizers">
                <Button
                    aria-label="button"
                    color="white"
                    style={{ color: "#a60101", fontWeight: 500 }}
                    onClick={props.tog}
                >
                    Kitchen Organizers
                </Button>
            </Link>
            <Link to="/e/dish-drainers">
                <Button
                    aria-label="button"
                    color="white"
                    style={{ color: "#a60101", fontWeight: 500 }}
                    onClick={props.tog}
                >
                    dish drainers
                </Button>
            </Link>
            <Link to="/e/chairs">
                <Button
                    aria-label="button"
                    color="white"
                    style={{ color: "#a60101", fontWeight: 500 }}
                    onClick={props.tog}
                >
                    Tables and Chairs
                </Button>
            </Link>
            <Link to="/e/boxes">
                <Button
                    aria-label="button"
                    color="white"
                    style={{ color: "#a60101", fontWeight: 500 }}
                    onClick={props.tog}
                >
                    Boxes
                </Button>
            </Link>
            <Link to="/e/others">
                <Button
                    aria-label="button"
                    color="white"
                    style={{ color: "#a60101", fontWeight: 500 }}
                    onClick={props.tog}
                >
                    others
                </Button>
            </Link>
        </div>
    );
}
